import * as React from 'react';

// Layout
import Layout from '../components/layout';
import CTABlock from '../components/cta_block';

// Data
import config from '../data/general';

const headerContent = (
  <div className="container mx-auto mt-4">
    <div className="py-8 md:py-16 hero__content text-center w-4/5 mx-auto fade-in fade-in-first">
      <h1 className="text-3xl md:text-5xl lg:text-5xl font-bold leading-tight">Powerful features</h1>
      <p className="text-xl pt-4 pb-8 text-gray-700">All the features your need. Not more.</p>
      <div className="flex justify-center items-center flex-col">
        <a className="bg-amcolor hover:bg-amcolor-darker rounded font-normal px-6 py-2 inline-block transition duration-500 ease-in-out cursor-pointer text-xl text-white" href={config.signup_link}>Build your backoffice →</a>
      </div>
    </div>
  </div>
);

const FeaturesPage = () => {
  return (
    <Layout headerContent={headerContent} headerExtraClass="bg-yellow-50 mb-8" pageTitle="Adminmate — Features">
      <div>
        <div className="py-8 text-center md:text-left mb-16 fade-in fade-in-second">
          <div className="container mx-auto">
            <div className="flex flex-row w-4/5 mx-auto">
              <div className="w-full md:w-3/4 md:mr-8">
                  <span className="text-yellow-500">All the features your need. Not more.</span>
                  <h2 className="text-xl font-semibold">The all-in-one solution for your team</h2>
                  <p className="text-md lg:text-lg pt-4 pb-8 text-gray-700">
                    We have worked with 50+ teams to select and develop all the necessary and essential features for your backoffice solution, while remaining fully customizable.
                  </p>
              </div>
              <div className="hidden md:block w-1/4 md:flex lg:flex justify-center items-center">
                <div className="text-5xl center"><i className="las la-check-circle text-amcolor"></i></div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:flex-wrap w-full md:w-4/5 mx-auto">
              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-table"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">Data explorer</h4>
                  <p className="mb-0">Simple and efficient</p>
                </div>
              </div>

              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-edit"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">CRUD</h4>
                  <p className="mb-0">For basic database operations</p>
                </div>
              </div>

              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-sort"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">Segments</h4>
                  <p className="mb-0">Preconfigured filters</p>
                </div>
              </div>

              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-filter"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">Filters</h4>
                  <p className="mb-0">Powerful filtering tool</p>
                </div>
              </div>
            </div>
            <div className="flex  flex-col md:flex-row md:flex-wrap my-4 w-full md:w-4/5 mx-auto my-4">
              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-mouse"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">Custom actions</h4>
                  <p className="mb-0">Custom API actions</p>
                </div>
              </div>

              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-tachometer-alt"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">Dashboards</h4>
                  <p className="mb-0">Unlimited dashboards for your team</p>
                </div>
              </div>

              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-chart-area"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">Charts</h4>
                  <p className="mb-0">Unlimited charts for your team</p>
                </div>
              </div>

              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-dot-circle"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">Environments</h4>
                  <p className="mb-0">Custom environments endpoints</p>
                </div>
              </div>
            </div>
            <div className="flex  flex-col md:flex-row md:flex-wrap my-4 w-full md:w-4/5 mx-auto my-4">
              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-users"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">Teams</h4>
                  <p className="mb-0">To organize your team</p>
                </div>
              </div>

              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-universal-access"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">Team-based access control</h4>
                  <p className="mb-0">To give access abilities to your teammates</p>
                </div>
              </div>

              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-list"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">Activities</h4>
                  <p className="mb-0">To track everything that happened</p>
                </div>
              </div>

              <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between">
                <div className="text-4xl"><i className="las la-comment"></i></div>
                <div>
                  <h4 className="mb-0 font-bold">Collaboration</h4>
                  <p className="mb-0">To be stronger together</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Block */}
        <CTABlock />

      </div>
    </Layout>
  )
};

export default FeaturesPage;